import React from 'react';
import style from '../css/AboutUs.module.css';
import Contact from '../components/Contact';
import Footer from '../components/Footer';

function About() {
	return (
		<div className={style.aboutUs}>
			<section className={style.introSection}>
				<h1>About Sannidi Groups</h1>
				<p>
					At Sannidi Groups, we are committed to delivering excellence across
					our diverse business segments—LPG distribution, software development,
					and construction. With a strong foundation built on innovation,
					integrity, and customer satisfaction, we are dedicated to empowering
					industries and creating lasting value for our clients and communities.
				</p>
			</section>

			<section className={style.missionVision}>
				<div className={style.mission}>
					<h2>Our Mission</h2>
					<p>
						To provide innovative, reliable, and sustainable solutions that
						drive success and create a positive impact on the industries we
						serve.
					</p>
				</div>
				<div className={style.vision}>
					<h2>Our Vision</h2>
					<p>
						To be a leading force in energy distribution, technology innovation,
						and construction excellence, shaping a better future for all.
					</p>
				</div>
			</section>

			<section className={style.valuesSection}>
				<h2>Our Core Values</h2>
				<div className={style.valuesGrid}>
					<div className={style.valueItem}>
						<h3>Integrity</h3>
						<p>
							We uphold the highest standards of integrity in all our actions
							and decisions.
						</p>
					</div>
					<div className={style.valueItem}>
						<h3>Innovation</h3>
						<p>
							We foster a culture of creativity and innovation, continuously
							improving our services and solutions.
						</p>
					</div>
					<div className={style.valueItem}>
						<h3>Customer Focus</h3>
						<p>
							Our customers are at the heart of everything we do, and we strive
							to exceed their expectations.
						</p>
					</div>
					<div className={style.valueItem}>
						<h3>Sustainability</h3>
						<p>
							We are committed to sustainable practices that protect our planet
							and benefit future generations.
						</p>
					</div>
				</div>
			</section>

			{/* <section className={style.teamSection}>
				<h2>Meet Our Team</h2>
				<div className={style.teamGrid}>
					<div className={style.teamMember}>
						<img src="/images/team1.jpg" alt="Team Member 1" />
						<h3>John Doe</h3>
						<p>CEO</p>
					</div>
					<div className={style.teamMember}>
						<img src="/images/team2.jpg" alt="Team Member 2" />
						<h3>Jane Smith</h3>
						<p>CTO</p>
					</div>
					<div className={style.teamMember}>
						<img src="/images/team3.jpg" alt="Team Member 3" />
						<h3>Robert Brown</h3>
						<p>Head of Construction</p>
					</div>
					<div className={style.teamMember}>
						<img src="/images/team4.jpg" alt="Team Member 4" />
						<h3>Emily Davis</h3>
						<p>Head of Software Development</p>
					</div>
				</div>
			</section> */}

			<Contact />
			<Footer />
		</div>
	);
}

export default About;
