import React, { useState } from 'react';
import style from '../css/SelectSales.module.css';
import SalesList from './SalesList';
import SiteSales from './SiteSales';

function SelectSales() {
	const [type, setType] = useState(true);

	return (
		<div className={style.container}>
			<div className={style.selectSalesContainer}>
				<div
					className={type ? style.selected : style.btn}
					onClick={() => setType(true)}
				>
					<p>Property</p>
				</div>
				<div
					className={type === false ? style.selected : style.btn}
					onClick={() => setType(false)}
				>
					<p>Sites</p>
				</div>
			</div>

			{type ? <SalesList /> : <SiteSales />}
		</div>
	);
}

export default SelectSales;
