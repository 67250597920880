import React from 'react';
import style from '../css/Footer.module.css';
import logo from '../assets/images/logo.png';
import { Link } from 'react-router-dom';
import { FaInstagram } from 'react-icons/fa6';

function Footer() {
	return (
		<div className={style.footer}>
			<div className={style.container}>
				<div className={style.logoCon}>
					<img src={logo} alt="" className={style.logo} />
					<p className={style.textDesc}>
						Driving progress through reliable energy, cutting-edge technology,
						and visionary construction—Sannidi Groups: Your partner in building
						a better tomorrow."
					</p>
				</div>

				<div className={style.footerSection}>
					<h3>Quick Links</h3>
					<ul>
						<li>
							<Link to="/">Home</Link>
						</li>
						<li>
							<Link to="/about">About Us</Link>
						</li>
						<li>
							<Link to="/service">Services</Link>
						</li>
						<li>
							<Link to="/contactus">Contact Us</Link>
						</li>
						{/* <li>
							<a href="/careers">Careers</a>
						</li> */}
					</ul>
				</div>

				<div className={style.footerSection}>
					<h3>Projects</h3>
					<ul>
						<li>
							<Link to="/softwareproject">Software Projects</Link>
						</li>

						<li>
							<Link to="/enterprise">Enterprise</Link>
						</li>

						<li>
							<Link to="/realestate">Real Estate</Link>
						</li>
						<li>
							<a
								href="https://www.instagram.com/sannidi_groups?igsh=MTZ3anhmcW9kcTJtOQ=="
								target="_blank"
								rel="noopener noreferrer"
								aria-label="Visit our Instagram page"
								className={style.instagram}
							>
								<FaInstagram size={25} />
							</a>
						</li>
					</ul>
				</div>

				<div className={style.footerSection}>
					<h3>Contact</h3>
					<a href="tel:+919113811925">Phone: +919113811925</a>
					<br />
					<a href="mailto:info@sannidi.org.in">Email: info@sannidi.org.in</a>
					<p>Address: Tin Factory, Bangalore - 560016.</p>
				</div>
			</div>

			<div className={style.footerBottom}>
				<p>&copy; 2024 Sannidi Groups. All rights reserved.</p>
			</div>
		</div>
	);
}

export default Footer;
