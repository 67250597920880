import React from 'react';
import style from '../css/WhatWeDo.module.css';

import building from '../assets/images/1.png';
import coding from '../assets/images/2.png';
import home from '../assets/images/3.png';
import ImageText from './ImageText';

const cardData = [
	{
		id: 1,
		header: 'SANNIDI ENTERPRISE',
		image: home,
		desc: 'Sannidi Enterprise is your trusted partner in the safe and reliable distribution of LPG commercial gas. We cater to a wide range of industries, providing bulk LPG supply, cylinder distribution, and expert installation and maintenance services. With a strong commitment to safety and efficiency, we ensure that your business operations run smoothly with a consistent and dependable energy source. Our solutions are tailored to meet the specific needs of each client, guaranteeing optimal performance and satisfaction.',
	},
	{
		id: 2,
		header: 'SANNIDI SOFTWARE',
		image: coding,
		desc: 'At Sannidi Software, we develop cutting-edge software solutions that drive business growth and efficiency. Specializing in custom software development, mobile app creation, and enterprise resource planning (ERP) systems, we offer innovative and scalable solutions that align with your business goals. Our team of skilled developers and IT professionals use the latest technologies to deliver high-quality, user-friendly applications that enhance productivity and provide a competitive edge. Whether you need a bespoke solution or a robust cloud service, Sannidi Software is here to make your vision a reality.',
	},
	{
		id: 3,
		header: 'REAL ESTATE',
		image: building,
		desc: "Sannidi Construction is dedicated to building high-quality infrastructure that stands the test of time. From residential projects to large-scale commercial developments, we offer comprehensive construction services, including project management, design, and execution. Our focus on quality, sustainability, and attention to detail ensures that every project is completed to the highest standards. Whether you're constructing a new building or renovating an existing structure, Sannidi Construction delivers excellence at every stage, turning your architectural visions into reality.",
	},
];

function WhatWeDo() {
	return (
		<div className={style.container}>
			<ImageText />
			<div className={style.rowDiv}>
				{cardData.map((card, index) => {
					return (
						<div className={style.main}>
							<img
								loading="lazy"
								src={card.image}
								alt=""
								className={style.image}
							/>
							<div>
								<p className={style.title}>{card.header}</p>
								<div className={style.con}>
									<div className={style.line} />
									<div className={style.circle} />
									<div className={style.line} />
								</div>
								<p className={style.desc}>{card.desc}</p>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
}

export default WhatWeDo;
