import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Home from '../pages/Home';
import Service from '../pages/Service';
import About from '../pages/About';
import ContactUs from '../pages/ContactUs';
import SoftwareProject from '../pages/SoftwareProject';
import Enterprise from '../pages/Enterprise';
import RealEstate from '../pages/RealEstate';
import Details from '../pages/Details';

function PageNavigation() {
	return (
		<Routes>
			<Route path="/" element={<Home />} />
			<Route path="/service" element={<Service />} />
			<Route path="/about" element={<About />} />
			<Route path="/contactus" element={<ContactUs />} />
			<Route path="/softwareproject" element={<SoftwareProject />} />
			<Route path="/enterprise" element={<Enterprise />} />
			<Route path="/realestate" element={<RealEstate />} />
			<Route path="/details/:id" element={<Details />} />
		</Routes>
	);
}

export default PageNavigation;
