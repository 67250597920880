import React, { useEffect, useState } from 'react';
import axios from 'axios';
import style from '../css/FetchImages2.module.css';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa6';

function FetchImages({ id }) {
	const [salesImage, setSalesImage] = useState([]);
	const [currentIndex, setCurrentIndex] = useState(0);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchSalesImage = async () => {
			setLoading(true);
			try {
				const response = await axios.get(
					`https://sannidi.org.in/website/sales/FetchImage.php?sales_ID=${id}`
				);
				console.log(response.data);
				setSalesImage(response.data.data);
				setCurrentIndex(0);
			} catch (error) {
				console.error('Error fetching sales images:', error);
			} finally {
				setLoading(false);
			}
		};

		if (id) {
			fetchSalesImage();
		}
	}, [id]);

	const handlePrevious = () => {
		setCurrentIndex((prevIndex) =>
			prevIndex > 0 ? prevIndex - 1 : salesImage.length - 1
		);
	};

	const handleNext = () => {
		setCurrentIndex((prevIndex) =>
			prevIndex < salesImage.length - 1 ? prevIndex + 1 : 0
		);
	};

	const handleCircleClick = (index) => {
		setCurrentIndex(index);
	};

	return (
		<div className={style.imageContainer}>
			{loading ? (
				<div className="loader"></div>
			) : salesImage.length > 0 ? (
				<div className={style.imageWrapper}>
					<img
						src={`https://sannidi.org.in/website/sales/${salesImage[currentIndex].image}`}
						alt="Sales Image"
						className={style.image}
					/>

					<div className={style.circleWrapper}>
						{salesImage.map((_, index) => (
							<span
								key={index}
								className={`${style.circle} ${
									currentIndex === index ? style.active : ''
								}`}
								onClick={() => handleCircleClick(index)}
							></span>
						))}
					</div>

					<div className={style.btnGroup}>
						<button onClick={handlePrevious} className={style.navButton}>
							<FaChevronLeft size={15} />
						</button>
						<button onClick={handleNext} className={style.navButton}>
							<FaChevronRight size={15} />
						</button>
					</div>
				</div>
			) : (
				<p>No images found.</p>
			)}
		</div>
	);
}

export default FetchImages;
