import React from 'react';
import style from '../css/Card.module.css';

function Card({ image, text }) {
	return (
		<div className={style.container}>
			<img src={image} alt="" className={style.image} loading="lazy" />
			<p className={style.text}>{text}</p>
		</div>
	);
}

export default Card;
