import React, { useState, useEffect } from 'react';
import axios from 'axios';
import style from '../css/Details.module.css';
import {
	FaBuildingUser,
	FaChevronDown,
	FaChevronUp,
	FaCouch,
	FaDiamond,
	FaPhone,
	FaRegBuilding,
	FaShare,
} from 'react-icons/fa6';
import { Link, useParams } from 'react-router-dom';
import FetchImages2 from '../components/FetchImages2';
import SalesList from '../components/SalesList';
import SelectSales from '../components/SelectSales';
import Footer from '../components/Footer';

function Details() {
	const [salesData, setSalesData] = useState(null);
	const [loading, setLoading] = useState(true);

	const { id } = useParams();

	useEffect(() => {
		const fetchSalesData = async () => {
			try {
				setLoading(true);
				const response = await axios.get(
					`https://sannidi.org.in/website/sales/FetchById.php?id=${id}`
				);
				console.log(response.data.data);
				setSalesData(response.data.data);
			} catch (error) {
				console.error('Error fetching sales data:', error);
			} finally {
				setLoading(false);
			}
		};

		fetchSalesData();
	}, [id]);

	const handleShare = (id) => {
		const shareUrl = `https://groups.sannidi.org.in/Details/${id}`;
		if (navigator.share) {
			navigator
				.share({
					title: 'Check out this property',
					url: shareUrl,
				})
				.catch((error) => console.error('Error sharing:', error));
		} else {
			prompt('Copy this link to share:', shareUrl);
		}
	};

	const formatDescription = (description) => {
		if (!description) return '';
		const parts = description.split('\n\n');
		return parts.map((part, index) => (
			<React.Fragment key={index}>
				<p className={style.descriptionText}>
					{part.split('\n').map((line, lineIndex) => (
						<React.Fragment key={lineIndex}>
							{line}
							<br />
						</React.Fragment>
					))}
				</p>
				{index < parts.length - 1 && <div className={style.separator}></div>}
			</React.Fragment>
		));
	};

	return (
		<>
			{loading ? (
				<div className={style.cent}>
					<div class="loader"></div>
				</div>
			) : (
				<div>
					{salesData ? (
						<div className={style.rowCon}>
							<div>
								<p className={style.title}>{salesData.title}</p>
								<p className={style.contact}>{salesData.location}</p>
							</div>
							<div>
								<FetchImages2 id={salesData.id} />
							</div>
							<div className={style.js}>
								<div className={style.moreRow}>
									<div className={style.hc}>
										<FaDiamond size={25} color="#D1D1D1" />
										<div>
											<p className={style.titleType}>Carpet Area</p>
											<p className={style.textType}>{salesData.area}</p>
										</div>
									</div>

									<div className={style.hc}>
										<FaRegBuilding size={25} color="#D1D1D1" />
										<div>
											<p className={style.titleType}>Status</p>
											<p className={style.textType}>{salesData.status}</p>
										</div>
									</div>

									<div className={style.hc}>
										<FaBuildingUser size={25} color="#D1D1D1" />
										<div>
											<p className={style.titleType}>Sale Type</p>
											<p className={style.textType}>{salesData.saleType}</p>
										</div>
									</div>

									<div className={style.hc1}>
										<FaCouch size={25} color="#D1D1D1" />
										<div>
											<p className={style.titleType}>Furniture</p>
											<p className={style.textType}>{salesData.furniture}</p>
										</div>
									</div>
								</div>
							</div>

							<div className={style.descriptionContainer}>
								{formatDescription(salesData.description)}
							</div>

							<div className={style.cd}>
								<div className={style.dc}>
									<Link className={style.btn} to={'tel:+919113811925'}>
										<FaPhone size={20} />
									</Link>

									<div
										className={style.btn}
										onClick={() => handleShare(salesData.id)}
									>
										<FaShare size={20} />
									</div>
								</div>
							</div>
						</div>
					) : (
						<p className="empty">No data available</p>
					)}

					<SelectSales />
					<Footer />
				</div>
			)}
		</>
	);
}

export default Details;
