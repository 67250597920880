import React from 'react';
import styles from '../css/ImageText.module.css';

function ImageText() {
	return (
		<div className={styles.container}>
			<h1 className={styles.txt}>SANNIDI GROUPS</h1>
		</div>
	);
}

export default ImageText;
