import React, { useState } from 'react';
import style from '../css/NavBar.module.css';
import logo from '../assets/images/logo.png';
import { Link } from 'react-router-dom';
import { FaBarsStaggered, FaPhoneVolume, FaXmark } from 'react-icons/fa6';

function NavBar() {
	const [show, setShow] = useState(false);

	return (
		<>
			<div className={style.container}>
				<Link to="/">
					<div className={style.imageDiv}>
						<img src={logo} alt="" className={style.image} />
					</div>
				</Link>

				<div className={style.BtnDiv}>
					<Link className={style.btn} to={'/'}>
						HOME
					</Link>

					<Link className={style.btn} to={'/Service'}>
						SERVICES
					</Link>

					<Link className={style.btn} to={'/About'}>
						About Us
					</Link>

					<Link className={style.btn} to={'/ContactUs'}>
						Contact Us
					</Link>

					<Link className={style.contactbtn} to={'tel:+919113811925'}>
						<FaPhoneVolume />
					</Link>
				</div>

				<div className={style.MobBtnDiv} onClick={() => setShow(!show)}>
					<FaBarsStaggered size={20} color="black" />
				</div>
			</div>
			{show ? (
				<div className={style.MobBtnGroup}>
					<div className={style.close} onClick={() => setShow(!show)}>
						<FaXmark size={25} color="white" />
					</div>

					<Link onClick={() => setShow(!show)} className={style.btn} to={'/'}>
						HOME
					</Link>

					<Link
						onClick={() => setShow(!show)}
						className={style.btn}
						to={'/service'}
					>
						SERVICES
					</Link>

					<Link
						onClick={() => setShow(!show)}
						className={style.btn}
						to={'/about'}
					>
						About Us
					</Link>

					<Link
						onClick={() => setShow(!show)}
						className={style.btn}
						to={'/contactus'}
					>
						Contact Us
					</Link>

					<Link
						onClick={() => setShow(!show)}
						className={style.btn}
						to={'/softwareproject'}
					>
						Project
					</Link>

					<Link
						onClick={() => setShow(!show)}
						className={style.btn}
						to={'/enterprise'}
					>
						Enterprise
					</Link>

					<Link
						onClick={() => setShow(!show)}
						className={style.btn}
						to={'/realestate'}
					>
						Real Estate
					</Link>

					<Link className={style.contactbtn} to={'tel:+919113811925'}>
						<FaPhoneVolume size={20} color="white" />
					</Link>
				</div>
			) : null}
		</>
	);
}

export default NavBar;
