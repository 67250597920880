import React, { useState } from 'react';
import style from '../css/ContactUs.module.css';
import Footer from '../components/Footer';
import WhatWeDo from '../components/WhatWeDo';

function ContactUs() {
	const [formData, setFormData] = useState({
		name: '',
		email: '',
		message: '',
	});
	const [responseMessage, setResponseMessage] = useState('');
	const [loading, setLoading] = useState(false); // State for loader

	// Handle form input changes
	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};

	// Handle form submission
	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true); // Start loader

		try {
			const response = await fetch(
				'https://sannidi.org.in/website/Insert.php',
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(formData),
				}
			);

			const data = await response.json();
			if (data.status === 'success') {
				setResponseMessage('Message sent successfully!');
				// Reset form data after successful submission
				setFormData({ name: '', email: '', message: '' });
			} else {
				setResponseMessage('Error sending message: ' + data.message);
			}
		} catch (error) {
			setResponseMessage('Network error: ' + error.message);
		} finally {
			setLoading(false); // Stop loader
		}
	};

	return (
		<div className={style.contactUs}>
			<section className={style.contactIntro}>
				<h1>Get in Touch with Us</h1>
				<p>
					We’re here to answer any questions you may have about our services.
					Reach out to us, and we’ll respond as soon as we can.
				</p>
			</section>

			<section className={style.contactDetails}>
				<div className={style.contactInfo}>
					<h2>Contact Information</h2>
					<p>
						<strong>Email:</strong> info@sannidi.org.in
					</p>
					<p>
						<strong>Phone:</strong> +919113811925
					</p>
					<p>
						<strong>Address:</strong> Tin Factory, Bangalore - 560016.
					</p>
				</div>
				<div className={style.mapContainer}>
					<h2>Our Location</h2>
					<iframe
						src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1943.803356814322!2d77.6674639346564!3d12.996987869945876!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae11393d59522b%3A0x1b2963311a1edbac!2sTin%20Factory!5e0!3m2!1sen!2sin!4v1725347310569!5m2!1sen!2sin"
						width="100%"
						height="300"
						style={{ border: '0' }}
						allowFullScreen=""
						loading="lazy"
						title="Our Location"
					></iframe>
				</div>
			</section>

			<section className={style.contactForm}>
				<h2>Send Us a Message</h2>
				<form onSubmit={handleSubmit}>
					<div className={style.formGroup}>
						<label htmlFor="name">Name</label>
						<input
							type="text"
							id="name"
							name="name"
							value={formData.name}
							onChange={handleChange}
							required
						/>
					</div>
					<div className={style.formGroup}>
						<label htmlFor="email">Email</label>
						<input
							type="email"
							id="email"
							name="email"
							value={formData.email}
							onChange={handleChange}
							required
						/>
					</div>
					<div className={style.formGroup}>
						<label htmlFor="message">Message</label>
						<textarea
							id="message"
							name="message"
							rows="5"
							value={formData.message}
							onChange={handleChange}
							required
						></textarea>
					</div>
					<button
						type="submit"
						className={style.submitButton}
						disabled={loading}
					>
						{loading ? 'Sending...' : 'Send Message'}
					</button>
				</form>
				{responseMessage && (
					<p className={style.responseMessage}>{responseMessage}</p>
				)}
				{loading && <div className={style.loader}>Loading...</div>}{' '}
				{/* Loader indicator */}
			</section>
			<WhatWeDo />
			<Footer />
		</div>
	);
}

export default ContactUs;
