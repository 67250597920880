import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import style from '../css/MidCard.module.css';

import building from '../assets/images/building.png';
import coding from '../assets/images/coding.jpg';
import home from '../assets/images/home.jpg';

const cardData = [
	{
		title: 'Sannidi Enterprise: Distributes LPG Commercial Gas',
		description:
			'Sannidi Enterprise is a leading distributor of LPG commercial gas, dedicated to providing safe, reliable, and environmentally friendly energy solutions to businesses across various sectors. Our commitment to safety, consistent supply, and exceptional customer support has established us as a trusted partner in the industry.',
		image: building,
	},
	{
		title: 'Sannidi Software',
		description:
			'Sannidi Software is dedicated to providing innovative and customized software solutions that empower businesses to achieve their goals. With a focus on quality and client satisfaction, our expert team delivers reliable and scalable software products, ensuring continuous support to meet evolving business needs.',
		image: coding,
	},
	{
		title: 'Sannidi Construction',
		description:
			'Sannidi Construction is a trusted name in the construction industry, offering comprehensive services that cover every aspect of building and development. We focus on high-quality standards, sustainable practices, and timely delivery, ensuring that each project we undertake is a testament to excellence and client satisfaction.',
		image: home,
	},
];

function MidCard() {
	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 3000,
	};

	return (
		<div className={style.container}>
			<Slider {...settings}>
				{cardData.map((card, index) => (
					<div key={index} className={style.card}>
						<div className={style.textContainer}>
							<div className={style.heading}>{card.title}</div>
							<div className={style.description}>{card.description}</div>
						</div>
					</div>
				))}
			</Slider>
		</div>
	);
}

export default MidCard;
