import React from 'react';
import style from '../css/Home.module.css';
import hero from '../assets/images/hero.png';
import logo from '../assets/images/logo.png';
import coding from '../assets/images/coding.jpg';
import business from '../assets/images/business.jpg';
import estate from '../assets/images/estate.jpg';
import Card from '../components/Card';
import MidCard from '../components/MidCard';
import WhatWeDo from '../components/WhatWeDo';
import WhyChooseUs from '../components/WhyChooseUs';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import Contact from '../components/Contact';
import ImageText from '../components/ImageText';
import Hero from '../components/Hero';
import SalesList from '../components/SalesList';
import SelectSales from '../components/SelectSales';
import { FaInstagram } from 'react-icons/fa6';
import InstagramEmbed from '../components/InstagramEmbed';

function Home() {
	return (
		<div className={style.container}>
			<Hero />

			<SelectSales />

			<div className={style.cardDiv}>
				<Card image={business} text={'sannidi enterprise'} />
				<Card image={estate} text={'real estate'} />
				<Card image={coding} text={'sannidi softwares'} />
			</div>

			<MidCard />
			<WhatWeDo />
			<WhyChooseUs />
			<Contact />
			<Footer />
		</div>
	);
}

export default Home;
