import React from 'react';
import style from '../css/SoftwareProject.module.css';
import Footer from '../components/Footer';

function SoftwareProject() {
	return (
		<div>
			<div className={style.softwareProject}>
				<h1>Coming Soon!</h1>
				<p>
					Our software project is currently under development. Stay tuned for
					updates as we work to bring you innovative solutions that will
					transform your business.
				</p>
				<p>
					If you have any questions or would like to learn more about our
					services, please <a href="/ContactUs">contact us</a>.
				</p>
			</div>
			<Footer />
		</div>
	);
}

export default SoftwareProject;
