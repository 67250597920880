import React, { useState } from 'react';
import style from '../../css/ScrollButton.module.css';
import {
	FaChevronLeft,
	FaChevronRight,
	FaInstagram,
	FaPhone,
	FaWhatsapp,
} from 'react-icons/fa6';

function Media() {
	const [show, setShow] = useState(true);

	return (
		<div className={style.containerMedia}>
			<button
				onClick={() => {
					setShow(!show);
				}}
				className={style.button}
			>
				<FaChevronLeft size={20} />
				<FaChevronRight size={20} />
			</button>
			{show ? (
				<div className={style.row}>
					<a
						href="https://www.instagram.com/sannidi_groups?igsh=MTZ3anhmcW9kcTJtOQ=="
						target="_blank"
						rel="noopener noreferrer"
						className={style.button}
					>
						<FaInstagram size={20} />
					</a>

					<a
						href="https://wa.me/+919113811925?text=Hi"
						target="_blank"
						rel="noopener noreferrer"
						className={style.button}
					>
						<FaWhatsapp size={20} />
					</a>

					<a
						href="tel:+919113811925"
						target="_blank"
						rel="noopener noreferrer"
						className={style.button}
					>
						<FaPhone size={20} />
					</a>
				</div>
			) : null}
		</div>
	);
}

export default Media;
