import React from 'react';
import WhatWeDo from '../components/WhatWeDo';
import Footer from '../components/Footer';
import Contact from '../components/Contact';
import SelectSales from '../components/SelectSales';

function Service() {
	return (
		<div>
			<WhatWeDo />
			<div style={{ marginTop: 50 }} />
			<SelectSales />
			<div style={{ marginTop: 50 }} />
			<Contact />
			<Footer />
		</div>
	);
}

export default Service;
