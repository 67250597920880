import React from 'react';
import style from '../css/WhyChooseUs.module.css';

const whyChooseUsPoints = [
	{
		id: 1,
		title: 'Industry Expertise',
		description:
			'With decades of experience, Sannidi Groups has established itself as a leader across multiple industries. Our extensive knowledge in LPG distribution, cutting-edge software development, and construction ensures that we deliver solutions that are both innovative and reliable. Our expertise allows us to navigate complex challenges and provide our clients with unparalleled service and insights that drive success.',
	},
	{
		id: 2,
		title: 'Customer-Centric Approach',
		description:
			'At Sannidi Groups, our clients are at the heart of everything we do. We take the time to understand your unique needs and challenges, ensuring that our services are customized to fit your specific requirements. Our commitment to building long-lasting relationships means that we are always focused on delivering exceptional value and exceeding your expectations at every step.',
	},
	{
		id: 3,
		title: 'Commitment to Safety and Quality',
		description:
			'Safety and quality are non-negotiable at Sannidi Groups. We implement rigorous safety protocols and quality control measures across all our operations, from LPG distribution to construction projects. Our unwavering dedication to maintaining the highest standards ensures that every project we undertake is completed with precision, reliability, and a commitment to excellence that sets us apart in the industry.',
	},
	{
		id: 4,
		title: 'Innovative Solutions',
		description:
			'Innovation is at the core of Sannidi Groups’ approach to problem-solving. We leverage the latest technologies and methodologies to create solutions that are not only effective but also future-proof. Whether it’s developing advanced software tailored to your business needs or implementing sustainable construction practices, our forward-thinking mindset ensures that we remain at the forefront of industry advancements.',
	},
	{
		id: 5,
		title: 'Reliable Service',
		description:
			'Reliability is a cornerstone of our operations. We pride ourselves on delivering consistent, high-quality service that you can depend on. From timely LPG deliveries to on-schedule project completions in construction, Sannidi Groups is known for its steadfast commitment to meeting deadlines and fulfilling promises, ensuring that your business operations run smoothly without interruptions.',
	},
	{
		id: 6,
		title: 'Sustainability Focus',
		description:
			'Sustainability is not just a buzzword at Sannidi Groups—it’s a guiding principle. We are dedicated to adopting environmentally responsible practices in all our services. Whether it’s reducing our carbon footprint in LPG distribution or using eco-friendly materials in construction, we strive to create solutions that benefit both our clients and the planet, ensuring a better future for all.',
	},
	{
		id: 7,
		title: 'Skilled Team',
		description:
			'Our team is our greatest asset. Comprising highly skilled professionals with expertise across various domains, our team is dedicated to delivering excellence in every project. Their deep industry knowledge, combined with a passion for innovation and a commitment to customer satisfaction, ensures that Sannidi Groups consistently delivers superior results, no matter the challenge.',
	},
];

const WhyChooseUs = () => {
	return (
		<div className={style.whychooseus}>
			<h2>Why Choose Us?</h2>
			<div className={style.pointscontainer}>
				{whyChooseUsPoints.map((point) => (
					<div key={point.id} className={style.point}>
						<h3>{point.title}</h3>
						<p>{point.description}</p>
					</div>
				))}
			</div>
		</div>
	);
};

export default WhyChooseUs;
