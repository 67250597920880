import React, { useState, useEffect } from 'react';
import axios from 'axios';
import style from '../css/SalesList.module.css';
import FetchImages from './FetchImages';
import {
	FaArrowRight,
	FaBuildingUser,
	FaChevronDown,
	FaChevronUp,
	FaCouch,
	FaDiamond,
	FaPhone,
	FaRegBuilding,
	FaShare,
} from 'react-icons/fa6';
import { Link, useNavigate } from 'react-router-dom';

function SalesList() {
	const [salesData, setSalesData] = useState([]);
	const [show, setShow] = useState();
	const [loading, setLoading] = useState(true);

	const navigate = useNavigate();

	useEffect(() => {
		const fetchSalesData = async () => {
			try {
				setLoading(true);
				const response = await axios.get(
					'https://sannidi.org.in/website/sales/FetchActive.php'
				);
				console.log(response.data);
				setSalesData(response.data.data);
			} catch (error) {
				console.error('Error fetching sales data:', error);
			} finally {
				setLoading(false);
			}
		};

		fetchSalesData();
	}, []);

	const handleShare = (id) => {
		const shareUrl = `https://groups.sannidi.org.in/Details/${id}`;
		if (navigator.share) {
			navigator
				.share({
					title: 'Check out this property',
					url: shareUrl,
				})
				.catch((error) => console.error('Error sharing:', error));
		} else {
			prompt('Copy this link to share:', shareUrl);
		}
	};

	const formatDescription = (description) => {
		if (!description) return '';
		const parts = description.split('\n\n');
		return parts.map((part, index) => (
			<React.Fragment key={index}>
				<p className={style.descriptionText}>
					{part.split('\n').map((line, lineIndex) => (
						<React.Fragment key={lineIndex}>
							{line}
							<br />
						</React.Fragment>
					))}
				</p>
				{index < parts.length - 1 && <div className={style.separator}></div>}{' '}
			</React.Fragment>
		));
	};

	return (
		<>
			{loading ? (
				<div class="loader"></div>
			) : (
				<div>
					{salesData.length > 0 ? (
						<div className={style.salesList}>
							{salesData.map((item) => {
								const isItemVisible = show === item.id;
								return (
									<div key={item.id} className={style.rowMain}>
										<div>
											<p className={style.title}>{item.title}</p>
										</div>
										<div className={style.rowCon}>
											<div>
												<FetchImages id={item.id} />
											</div>
											<div className={style.js}>
												<div className={style.moreRow}>
													<div className={style.hc}>
														<FaDiamond size={25} color="#D1D1D1" />
														<div>
															<p className={style.titleType}>Carpet Area</p>
															<p className={style.textType}>{item.area}</p>
														</div>
													</div>

													<div className={style.line} />

													<div className={style.hc}>
														<FaRegBuilding size={25} color="#D1D1D1" />
														<div>
															<p className={style.titleType}>Status</p>
															<p className={style.textType}>{item.status}</p>
														</div>
													</div>

													<div className={style.line} />

													<div className={style.hc}>
														<FaBuildingUser size={25} color="#D1D1D1" />
														<div>
															<p className={style.titleType}>Sale Type</p>
															<p className={style.textType}>{item.saleType}</p>
														</div>
													</div>

													<div className={style.line} />

													<div className={style.hc}>
														<FaCouch size={25} color="#D1D1D1" />
														<div>
															<p className={style.titleType}>Furniture</p>
															<p className={style.textType}>{item.furniture}</p>
														</div>
													</div>
												</div>
												<p className={style.contact}>
													<a
														href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
															item.location
														)}`}
														target="_blank"
														rel="noopener noreferrer"
														className={style.mapLink}
													>
														{item.location}
													</a>
												</p>
											</div>

											<div className={style.dc}>
												<Link className={style.btn} to={'tel:+919113811925'}>
													<FaPhone size={20} />
												</Link>

												<div
													className={style.btn}
													onClick={() => handleShare(item.id)}
												>
													<FaShare size={20} />
												</div>

												<Link to={`/details/${item.id}`} className={style.next}>
													<FaArrowRight size={20} />
												</Link>
												{/* <div
													onClick={() =>
														setShow(isItemVisible ? null : item.id)
													}
													className={style.next}
												>
													{isItemVisible ? (
														<FaChevronUp size={20} />
													) : (
														<FaChevronDown size={20} />
													)}
												</div> */}
											</div>
										</div>

										{isItemVisible && (
											<div className={style.descriptionContainer}>
												{formatDescription(item.description)}
											</div>
										)}
									</div>
								);
							})}
						</div>
					) : (
						<p className="empty">No data available</p>
					)}
				</div>
			)}
		</>
	);
}

export default SalesList;
