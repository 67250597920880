import React from 'react';
import style from '../css/Enterprise.module.css';
import Footer from '../components/Footer';

function Enterprise() {
	return (
		<div>
			<div className={style.enterprise}>
				<h1>Coming Soon!</h1>
				<p>
					Our enterprise solutions are currently in development. We are
					dedicated to providing you with reliable and efficient LPG
					distribution services.
				</p>
				<p>
					Stay tuned for updates, or feel free to
					<a href="/contact"> contact us</a> if you have any questions!
				</p>
			</div>
			<Footer />
		</div>
	);
}

export default Enterprise;
