import React from 'react';
import style from '../../css/ScrollButton.module.css';
import { FaChevronUp } from 'react-icons/fa6';

function ScrollButton() {
	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};

	return (
		<div className={style.container}>
			<button onClick={scrollToTop} className={style.button}>
				<FaChevronUp size={20} />
			</button>
		</div>
	);
}

export default ScrollButton;
