import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import NavBar from './navigation/NavBar';
import PageNavigation from './navigation/PageNavigation';
import './App.css';
import ScrollToTop from './components/scroll/ScrollToTop';
import ScrollButton from './components/scroll/ScrollButton';
import Media from './components/scroll/Media';

function App() {
	return (
		<Router>
			<ScrollToTop />
			<div>
				<div className="sticky">
					<NavBar />
				</div>
				<ScrollButton />
				<Media />
				<PageNavigation />
			</div>
		</Router>
	);
}

export default App;
