import React from 'react';
import style from '../css/RealEstate.module.css';
import Footer from '../components/Footer';
import SelectSales from '../components/SelectSales';

function RealEstate() {
	return (
		<div className={style.realEstate}>
			<div>
				<SelectSales />
			</div>
			<Footer />
		</div>
	);
}

export default RealEstate;
