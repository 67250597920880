import React from 'react';
import style from '../css/Home.module.css';
import { Link } from 'react-router-dom';

const Contact = () => {
	return (
		<div className={style.contact}>
			<p className={style.conTitle}>Contact</p>
			<p className={style.conTxt}>
				For more details on our comprehensive services—whether you're seeking
				reliable LPG distribution, innovative software solutions, or
				high-quality construction—please do not hesitate to contact us. Our
				dedicated team is ready to assist you in fueling your business,
				transforming your technology landscape, and building your next project
				with precision and care. Let’s work together to turn your aspirations
				into reality across all facets of your business.
			</p>
			<Link to={'/contactus'} className={style.btn}>
				<p>Contact</p>
			</Link>
		</div>
	);
};

export default Contact;
