import React from 'react';
import style from '../css/Home.module.css';
import hero from '../assets/images/hero.png';

function Hero() {
	return (
		<div>
			<div className={style.imgDiv}>
				<img className={style.img} src={hero} alt="building" loading="lazy" />

				<div className={style.txtDiv}>
					<h3 className={style.header}>Vision & Mission</h3>
					<p className={style.title}>01. Vision</p>

					<p>
						To be a leader in innovative solutions across software development,
						construction, and commercial LPG enterprises, driving sustainable
						growth and empowering communities through technology and
						infrastructure excellence.
					</p>

					<p className={style.title}>02. Mission</p>
					<p>
						Our mission is to deliver high-quality software solutions, robust
						construction services, and safe, efficient LPG products that meet
						the diverse needs of our clients. We are committed to:
					</p>

					<ul>
						<li>
							<strong>Innovation: </strong>
							<em>
								Utilizing cutting-edge technology to create efficient and
								user-friendly software applications.
							</em>
						</li>
						<li>
							<strong>Quality: </strong>
							<em>
								Ensuring the highest standards in construction projects,
								delivering safe and sustainable infrastructures.
							</em>
						</li>
						<li>
							<strong>Customer Satisfaction: </strong>
							<em>
								Building lasting relationships with our clients by understanding
								their needs and exceeding their expectations.
							</em>
						</li>
						<li>
							<strong>Sustainability: </strong>
							<em>
								Promoting environmentally responsible practices in all our
								operations to contribute to a greener future.
							</em>
						</li>
						<li>
							<strong>Community Empowerment: </strong>
							<em>
								Supporting local communities through job creation and economic
								development initiatives.
							</em>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
}

export default Hero;
