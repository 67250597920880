import React from 'react';

function SiteSales() {
	const containerStyle = {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		backgroundColor: '#f4f4f4',
		color: '#333',
		padding: '3rem',
		borderRadius: '20px',
	};

	const headingStyle = {
		fontSize: '3rem',
		marginBottom: '20px',
	};

	const messageStyle = {
		fontSize: '1.5rem',
		color: '#666',
	};

	return (
		<div style={containerStyle}>
			<h1 style={headingStyle}>Coming Soon</h1>
			<p style={messageStyle}>
				The section will be available soon. Stay tuned for updates!
			</p>
		</div>
	);
}

export default SiteSales;
